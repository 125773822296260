/*Only mobile*/

@media only screen and (max-width: 575px) {
  .navbar-collapse {
    background: var(--vuz-secondary-color);
    position: relative;
    top: 50px;
    margin: 0 -15px;
  }
  .mainmenu .navbar-nav a {
    margin-right: 0 !important;
  }
  .mobile-cookieBar {
    width: 100% !important;
  }
  .headinglong {
    width: 99%;
  }
  .headingshort {
    width: 80%;
    margin-left: 10%;
  }
  .news-inline-content {
    margin-left: 0 !important;
  }
}

/*Only tablet*/

@media screen and (max-width: 992px) and (min-width: 576px) {
  .mainmenu {
    height: auto;
    min-height: 210px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/*Only desktop*/

@media only screen and (min-width: 1024px) {
  .special-marker {
    margin-left: -8px;
  }

  .special-marker:after {
    content: '';
    position: absolute;
    height: 0px;
    width: 0px;
    margin-top: -18px;
    margin-left: -20px;
    border-color: transparent transparent transparent var(--vuz-color);
    border-style: solid;
    border-width: 0px 40px 24px 40px;
    transform: rotate(270deg);
  }
}
