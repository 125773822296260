:root {
  --vuz-color: #005396;
  --vuz-secondary-color: #0288d1;
  --vuz-heading-color: #014497;
}

/* Hide Recaptcha*/
.grecaptcha-badge {
  visibility: hidden;
}

html,
body {
  margin: 0;
  padding: 0;
  line-height: 1.4;
  scroll-behavior: smooth;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  /* 400, 500, 700*/
}

h1 {
  font-weight: 700;
  font-size: 80px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

/* Override MUI Label style */
.MuiTextField-root > label {
  background-color: white;
  padding: 0px 10px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.notLastButton {
  margin-right: 15px !important;
}

.imgbg {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-color: var(--vuz-color) !important;
}

.mainmenu {
  background: url('img/header-menu-bg.png');
  height: 210px;
  font-size: 24px;
}

.headerimg {
  background: url('img/header-main-bg.jpg');
  min-height: 550px;
}

.footer {
  background: url('img/footer-bg.png');
  min-height: 200px;
}

.mainmenu .navbar-nav a {
  color: white !important;
  text-decoration: none;
  opacity: 1;
}

.mainmenu .navbar-nav a:hover {
  color: rgb(238 238 238) !important;
  opacity: 0.9;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.3s;
  -moz-transition: -moz-transform 0.4s, opacity 0.3s;
  transition: transform 0.4, opacity 0.3s;
}

.mainmenu .navbar-nav a:not(:last-child) {
  margin-right: 20px;
}

.pageup {
  position: fixed;
  z-index: 10;
  right: 10px;
  bottom: 20px;
}

.headertxt {
  margin-top: 150px;
  color: white;
}

.headertxt a {
  color: white;
  text-decoration: none;
}

.headertxt a:hover {
  text-decoration: underline;
}

.subtitle {
  font-size: 40px !important;
}

.heading {
  background-color: var(--vuz-heading-color);
  color: white;
  font-weight: 500;
  font-size: 20px;
  margin: 50px 0 50px 0;
  padding: 5px 0 5px 0;
  border-radius: 50px;
}

.headinglong {
  width: 100%;
}

.headingshort {
  width: 50%;
  margin-left: 25%;
}

.heading-text {
  margin-bottom: 0;
  font-size: 20px;
}

.iframe-map {
  max-width: 300px;
}

.contact-profile {
  width: 300px;
}

.profile ul {
  list-style: none;
  padding-left: 0 !important;
}

.news-prev-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 15px;
}

.news-inline {
  padding-bottom: 0 !important;
}

.news-inline-content {
  margin-left: 10px;
  font-size: 20px;
}

.lighbox-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.lightbox-item {
  max-height: 150px;
  padding-right: 1em;
  padding-bottom: 0.5em;
}

.news-prev-img {
  width: 300px;
}

.news-prev-placeholder {
  filter: invert(85%) sepia(51%) saturate(4201%) hue-rotate(205deg)
    brightness(87%) contrast(99%);
  width: 240px;
  margin: 29px 30px;
}

.news-prev-content {
  display: row;
  max-width: 70%;
  padding: 16px;
}

.news-prev-date {
  font-size: 0.75rem;
  color: #646264;
}

.special-marker {
  background: var(--vuz-color);
  color: white;
  padding: 8px;
}

.special-marker a {
  color: white;
}
